import * as R from "runtypes";

import {
  ExtractAtomicOperationFromDefinition,
  createAtomicOperationDefinition,
} from "../atomic-operations/AtomicOperationDefinition";
import { AuthRule } from "../AuthRule.js";
import { TimezoneName } from "../dateTypes";
import { AppRunState, KernelState } from "../enums";
import { AppSessionId, CellId, UserId } from "../idTypeBrands";
import { SemanticCap } from "../semanticCaps";

const RUN_APP_SESSION_TYPE = "RUN_APP_SESSION" as const;
export const DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH = {
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.EDIT_APP_SESSION,
  },
} as Record<"readAuth" | "writeAuth", AuthRule>;

export const RUN_APP_SESSION = createAtomicOperationDefinition({
  type: RUN_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: ["reset", "triggeringButtonCellId"],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: (args: {
    /** Code to run before running anything else. This code is not run if there's a triggering button. */
    initialSource?: string;
    reset?: boolean;
    triggeringButtonCellId?: CellId;
    forceOverwriteCache?: boolean;
    executeTrace?: boolean;
  }) => ({
    type: RUN_APP_SESSION_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type RUN_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof RUN_APP_SESSION
>;

const START_APP_SESSION_TYPE = "START_APP_SESSION" as const;
export const START_APP_SESSION = createAtomicOperationDefinition({
  type: START_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: [],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: () => ({
    type: START_APP_SESSION_TYPE,
    payload: {},
  }),
});
export type START_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof START_APP_SESSION
>;

const RESTART_APP_SESSION_TYPE = "RESTART_APP_SESSION" as const;
export const RESTART_APP_SESSION = createAtomicOperationDefinition({
  type: RESTART_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: [],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: () => ({
    type: RESTART_APP_SESSION_TYPE,
    payload: {},
  }),
});

export type RESTART_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof RESTART_APP_SESSION
>;

const RESTART_AND_RUN_APP_SESSION_TYPE = "RESTART_AND_RUN_APP_SESSION" as const;
export const RESTART_AND_RUN_APP_SESSION = createAtomicOperationDefinition({
  type: RESTART_AND_RUN_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: [],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: () => ({
    type: RESTART_AND_RUN_APP_SESSION_TYPE,
    payload: {},
  }),
});

export type RESTART_AND_RUN_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof RESTART_AND_RUN_APP_SESSION
>;

const STOP_APP_SESSION_TYPE = "STOP_APP_SESSION" as const;
export const STOP_APP_SESSION = createAtomicOperationDefinition({
  type: STOP_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: ["errored", "kernelStatus"],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: (args: {
    errored?: boolean;
    kernelStatus?: KernelState;
    killed?: boolean;
  }) => ({
    type: STOP_APP_SESSION_TYPE,
    payload: args,
  }),
});

export type STOP_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof STOP_APP_SESSION
>;

export const UpdateAppStatusOptions = R.Record({
  suppressScheduledRunFailureNotification: R.Optional(R.Boolean),
});
export type UpdateAppStatusOptions = R.Static<typeof UpdateAppStatusOptions>;

const UPDATE_APP_STATUS_TYPE = "UPDATE_APP_STATUS" as const;
export const UPDATE_APP_STATUS = createAtomicOperationDefinition({
  type: UPDATE_APP_STATUS_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: ["state"],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS_TYPE,
  create: (state: AppRunState, options: UpdateAppStatusOptions = {}) => ({
    type: UPDATE_APP_STATUS_TYPE,
    payload: {
      state,
      suppressScheduledRunFailureNotification:
        options?.suppressScheduledRunFailureNotification ?? false,
    },
  }),
});

export type UPDATE_APP_STATUS = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_APP_STATUS
>;

const INTERRUPT_APP_SESSION_TYPE = "INTERRUPT_APP_SESSION" as const;
export const INTERRUPT_APP_SESSION = createAtomicOperationDefinition({
  type: INTERRUPT_APP_SESSION_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: [],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: () => ({
    type: INTERRUPT_APP_SESSION_TYPE,
    payload: {},
  }),
});

export type INTERRUPT_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof INTERRUPT_APP_SESSION
>;

export interface AppSessionUpdatableFields {
  // If ever adding a new field here that is sensitive, update logSafe to remove the "value" field
  totalMemory: string | null;
  availableMemory: string | null;
  maxMemory: string | null;
  lastRunStart: Date | null;
  lastRunEnd: Date | null;
  timezone: TimezoneName | null;
  oldestCacheEntryDate: Date | null;
  sessionOwnerId: UserId | null;
  name: string | null;
}

const _UpdateAppSessionSafeFields: {
  [key in keyof AppSessionUpdatableFields]: "";
} = {
  totalMemory: "",
  availableMemory: "",
  maxMemory: "",
  lastRunStart: "",
  lastRunEnd: "",
  timezone: "",
  oldestCacheEntryDate: "",
  sessionOwnerId: "",
  name: "",
};
export const UpdateAppSessionSafeFields = Object.keys(
  _UpdateAppSessionSafeFields,
);

const UPDATE_APP_SESSION_TYPE = "UPDATE_APP_SESSION" as const;
export const UPDATE_APP_SESSION = createAtomicOperationDefinition({
  type: "UPDATE_APP_SESSION",
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  writeAuth: {
    kind: "noAuth",
    message: "appSessionId is checked based on the key passed in manually",
    idArg: "appSessionId",
  },
  // Value is safe to log here as none of the fields in UpdateAppSessionSafeFields are sensitive.
  // If we ever add a new field that contains user data or sensitive internals, we should exclude the value
  logSafe: ({ dangerouslyUnsafe, operation: { payload } }) => ({
    appSessionId: payload.appSessionId,
    key: payload.key,
    value:
      dangerouslyUnsafe || payload.key !== "name"
        ? payload.value
        : "<redacted>",
  }),
  conflictId: (op) =>
    `${UPDATE_APP_SESSION_TYPE}-${op.payload.appSessionId}-${op.payload.key}`,
  create: <K extends keyof AppSessionUpdatableFields>(
    appSessionId: AppSessionId,
    key: K,
    value: AppSessionUpdatableFields[K],
  ) => ({
    type: UPDATE_APP_SESSION_TYPE,
    payload: {
      appSessionId,
      key,
      value,
    },
  }),
});
export type UPDATE_APP_SESSION = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_APP_SESSION
>;

const CLEAR_OUTPUTS_TYPE = "CLEAR_OUTPUTS" as const;
export const CLEAR_OUTPUTS = createAtomicOperationDefinition({
  type: CLEAR_OUTPUTS_TYPE,
  ...DEFAULT_APP_SESSION_READ_AND_WRITE_AUTH,
  logSafe: [],
  alwaysSkipUndoBuffer: true,
  conflictId: () => UPDATE_APP_STATUS.type,
  create: () => ({
    type: CLEAR_OUTPUTS_TYPE,
    payload: {},
  }),
});

export type CLEAR_OUTPUTS = ExtractAtomicOperationFromDefinition<
  typeof CLEAR_OUTPUTS
>;
